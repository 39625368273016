<template>
	<PageLayout
		:title="!currentWithdrawal && $t('dashboard.withdrawal')"
		:subtitle="!currentWithdrawal && $t('deposit.select.title')"
		wrapper-class="select-wrapper"
	>
		<div>
			<div v-if="currentWithdrawal && !loading" class="pending-withdrawal">
				<img src="../../../assets/icons/pending.svg" style="max-height: 60%; z-index: 1000" />

				<h1>{{ $t('dashboard.withdrawal') }}</h1>
				<span>
					{{
						$t('withdrawal.you-currently-withdrawal', {
							amount: formatUsd(currentWithdrawal.amount),
							method: currentWithdrawal.method || 'default',
						})
					}}
				</span>
			</div>
			<div v-if="!currentWithdrawal && !loading" class="available-method-wrapper">
				<div v-for="(method, index) in availableMethods" :key="index" class="methods">
					<div class="available-methods">
						<div class="method-heading black-method logo-method" @click="showMethodDetails(method.name)">
							<img v-if="!!method.icon" :src="method.icon" style="max-height: 70%; object-fit: contain" />
							<span v-if="!method.icon">{{ method.name }}</span>
						</div>
						<template v-if="innerWidth > 576 || methodName.includes(method.name)">
							<div class="method-heading gray-method info-value min-value">
								<div class="attr">
									{{ $t('global.min') }}
								</div>
								<div class="value">
									{{ formatUsd(method.min || 0) }}
								</div>
							</div>
							<div class="method-heading black-method info-value max-value">
								<div class="attr">
									{{ $t('global.max') }}
								</div>
								<div class="value">
									{{ method.max ? formatUsd(method.max) : '∞' }}
								</div>
							</div>
							<div class="method-heading gray-method info-value fee-value">
								<div class="attr">{{ $t('global.fee') }}*</div>
								<div class="value">
									{{ $t('global.free') }}
								</div>
							</div>
							<div class="method-heading black-method go-to-method">
								<button type="button" class="button primary-button" @click="goToMethod(method.id)">
									{{ $t('deposit.withdraw') }}
								</button>
							</div>
						</template>
					</div>
					<template v-if="innerWidth > 576 || methodName.includes(method.name)">
						<div class="description-wrapper">
							<div class="description-text">
								{{ $t(`deposit.method-descriptions.${method.id}`) }}
							</div>
						</div>
					</template>
				</div>
			</div>
			<div v-if="!currentWithdrawal" class="methods-countries-wrapper">
				<div>
					<img class="flag" :src="getFlagImage" />
				</div>
				<div>
					{{ $t('deposit.payments-based-on-country') }}
					<a href="/profile">{{ $t('actions.click-country-change') }}</a>
				</div>
			</div>
			<p class="fineprint">
				{{ $t('deposit.additional-info') }}
			</p>
			<div v-if="loading" class="loader-wrapper">
				<loader />
			</div>
		</div>
	</PageLayout>
</template>
<script>
import apiClient from '@/api';
import PageLayout from '@/components/page-layout/PageLayout';

const loader = () => import('../../../shared/loader/Loader.vue');

export default {
	name: 'WithdrawalSelect',
	components: {
		PageLayout,
		loader,
	},
	data() {
		return {
			loading: false,
			innerWidth: null,
			methodName: [],
			availableMethods: [],
			method: null,
			currentWithdrawal: null,
		};
	},
	computed: {
		countryCode() {
			return this.getUser.country;
		},
		getFlagImage() {
			try {
				return require(`../../../assets/circular-flags/${this.countryCode.toLowerCase()}.svg`);
			} catch (e) {
				return require('../../../assets/circular-flags/nocountry.svg');
			}
		},
	},
	beforeMount() {
		this.innerWidth = window.innerWidth;
		window.addEventListener('resize', this.onResize);
		this.fetchMethods();
		this.fetchCurrentWithdrawal();
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		goToMethod(id) {
			// removes hideBack from query params - Vue router forwards all query params when in iFrame it seems
			// eslint-disable-next-line no-unused-vars
			const { hideBack, ...rest } = this.$route.query;
			const { walletId } = this.$route.params;
			const url = `/payments/withdrawal/${walletId}/${id}`;

			this.$router.push({
				path: url,
				query: rest,
			});
		},
		formatUsd(amount) {
			const formatter = new Intl.NumberFormat('en', {
				style: 'currency',
				currency: 'USD',
				currencyDisplay: 'symbol',
			});
			return formatter.format(amount);
		},
		showMethodDetails(methodName) {
			if (this.innerWidth <= 576) {
				const index = this.methodName.indexOf(methodName);
				if (index > -1) {
					this.methodName.splice(index, 1);
				} else {
					this.methodName.push(methodName);
				}
			} else {
				this.methodName = [];
			}
		},
		onResize() {
			this.innerWidth = window.innerWidth;
			if (this.innerWidth <= 576) {
				this.methodName = [];
			}
		},
		async fetchCurrentWithdrawal() {
			const response = await apiClient.currentWithdrawal();
			this.currentWithdrawal = response.data.withdrawal;
		},
		async fetchMethods() {
			try {
				this.loading = true;
				const resp = await apiClient.getAvailableWithdrawalMethods();
				this.availableMethods = resp.data.methods.map((m) => ({
					id: m.id,
					name: m.name,
					icon: m.icon ? require(`../../../assets/payment-icons/${m.icon}`) : null,
					min: m.min,
					max: m.max,
				}));
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
<style scoped lang="scss">
.methods-countries-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 12px;
}

.flag {
	margin-right: 10px;
}

::v-deep .select-wrapper {
	max-width: 1200px !important;
}

.pending-withdrawal {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 20px;
}

.pending-withdrawal > span {
	width: 70%;
}
</style>
